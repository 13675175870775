html{
	background-color: #01062F;	
}

body {
	margin: 0;
	padding: 0;
	/* display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #01062F; */
}

p,
a {
	position: relative;
	font-family: Geneva, sans-serif;
	font-size: 30px;
	text-transform: uppercase;
	letter-spacing: 2px;
	background: linear-gradient(90deg, #000, #fff, #000);
	background-repeat: no-repeat;
	background-size: 80%;
	animation: animate 2s infinite ease-in-out;
	-webkit-background-clip: text;
	-webkit-text-fill-color: rgba(255, 255, 255, .5);
	margin: 0px 30px;
}

@keyframes animate {
	0% {
		background-position: -500%;
	}

	100% {
		background-position: 500%;
	}
}


@font-face {
	font-family: "DidotLH-Roman";
	src: local("DidotLH-Roman"),
		url("./fonts/DidotLH-Roman.otf") format("truetype");
	font-weight: bold;
}

.main {
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: #01062F;
	text-align: center;
	flex-direction: column;
}

.text-bottom {
	position: absolute;
	bottom: 0px;
	font-size: .9rem;
	margin-bottom: 10px;
}

.font-face-gm {
	font-family: "DidotLH-Roman";
}

@media screen and (max-width: 750px) {
	.phone-restrict {
		font-size: 25px;
	}

	.phone-restrict-lot {
		font-size: 15px;
	}
}

@media screen and (max-width: 395px) {
	.phone-restrict {
		font-size: 20px;
	}

	.phone-restrict-lot {
		font-size: 15px;
	}
}